<template>
	<div class="grid p-fluid">
		<!-- <div class="col-12 md:col-6">
			<div class="card">
				<h5>Join Organization</h5>
                <span>Get access to your organization's saved views by entering your organization code below:</span>
				<div class="grid formgrid">
					<div class="col-12 mt-2 mb-2 lg:col-9">
						<InputText v-model="orgCode" type="text" placeholder="Organization Code"></InputText>
					</div>
                    <div class="col-12 mt-2 mb-2 lg:col-3">
						<Button label="Submit" @click="addOrg" />
					</div>
				</div>
			</div>
            <div v-if="orgList.length > 0" class="card">
				<h5>Current Organizations</h5>
                <ul>
                    <li v-for="(organization, index) in orgList" :key="index">{{ organization.name }} <i class="pi pi-times cursor-pointer text-red-300 vertical-align-middle" @click="removeOrg(organization.id, index)"></i></li>
                </ul>
			</div>
		</div> -->
        <div class="col-12 md:col-6">
			<div class="card">
				<h5>Display Name</h5>
				<div class="grid formgrid">
					<div class="col-12 mt-2 mb-2 lg:col-9">
						<InputText v-model="displayName" type="text" placeholder="Display Name"></InputText>
					</div>
                    <div class="col-12 mt-2 mb-2 lg:col-3">
						<Button label="Submit" @click="changeName" />
					</div>
				</div>

				<h5>Email Notifications</h5>
				<InputSwitch v-model="digestPref" @change="updateDigest" /><br />
                <span>Get updates on new features</span>
			</div>
        </div>
	</div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { fireauth, firedb } from "@/firebase";
import { doc, getDoc, updateDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore";

export default {
    data() {
			return {
                user: null,
                orgCode: "",
                orgList: [],
				displayName: "",
                digestPref: false,
			}
		},
    methods: {
        async addOrg() {
            this.$toast.add({severity:'info', summary: 'Verifying...', detail:'Checking for a matching organization.', life: 3000});
            const querySnapshot = await getDocs(query(collection(firedb, "org"), where("access_code", "==", this.orgCode)));
            querySnapshot.forEach(async (docResult) => {
                this.orgList.push({"id": docResult.id, "name": docResult.data().name});
                await updateDoc(doc(firedb, "user", fireauth.currentUser.uid), {
                    orgs: this.orgList,
                    updated_timestamp: serverTimestamp(),
                });
                this.orgCode = "";
                this.$toast.add({severity:'success', summary: 'Success', detail:'New organization added.', life: 3000});
            });
            if (querySnapshot.size == 0) {
                this.$toast.add({severity:'error', summary: 'Error', detail:'No matching organization found.', life: 3000});
            }
        },
        removeOrg(orgId, index) {
            console.log(index);
            this.$confirm.require({
                message: 'Are you sure you want to remove this organization? You will need to re-enter the access code to get access to saved views again.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.orgList.splice(index,1);
                    await updateDoc(doc(firedb, "user", fireauth.currentUser.uid), {
                        orgs: this.orgList,
                        updated_timestamp: serverTimestamp(),
                    });
                    this.$toast.add({severity:'success', summary: 'Success', detail:'Organization Removed', life: 3000});
                },

            });
        },
        async changeName() {
            await updateDoc(doc(firedb, "user", fireauth.currentUser.uid), {
                display_name: this.displayName,
                updated_timestamp: serverTimestamp(),
            });
            this.$toast.add({severity:'success', summary: 'Update Successful', detail:'Your display name was changed', life: 3000});
        },
        async updateDigest() {
            await updateDoc(doc(firedb, "user", fireauth.currentUser.uid), {
                "notification_email": this.digestPref,
                updated_timestamp: serverTimestamp(),
            });
            this.$toast.add({severity:'success', summary: 'Update Successful', detail:'Your notification settings were changed', life: 3000});
        },
        async getUser(n=5) {
            if (fireauth.currentUser) {
                let docSnap = await getDoc(doc(firedb, "user", fireauth.currentUser.uid))
                if (docSnap.exists()) {
                    this.user = docSnap.data();
                    this.displayName = docSnap.data().display_name;
                    this.digestPref = docSnap.data().notification_email;
                    this.orgList = docSnap.data().orgs;
                } else {
                    console.log("No user document found");
                    setTimeout(() => {
                        n--;
                        this.getUser(n);
                    }, 1000)
                }
            }
            else {
                setTimeout(() => {
                    n--;
                    this.getUser(n);
                }, 1000)
            }
        },
    },
    mounted() {
        this.getUser();
    }
}
</script>

<style scoped>
</style>